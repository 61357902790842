<template>
  <div class="democracy-inviter-view">
    <tojoy-title-content title="邀请评价人" />
    <section class="democracy-inviter-view__section">
      <div class="democracy-inviter-view__section_state">
        <small-user-card
          :size="64"
          border
          :data="{
            avatar: avatar,
            username: userName,
            position: jobName,
            organization: orgChain
          }"
        />
        <!-- 员工邀请人 -->
        <div v-if="$route.query.wayType == 1 && currentState">
          <component :is="'InviterTitleState'" :inviteS="currentState" :data="inviterPerson" :state="invite" />
        </div>
        <!-- 展示人员 -->
        <div v-else class="allStype">
          <!-- 员工  -->
          <p v-if="$route.query.type == 1">
            共有
            <span class="org">{{ user.total }}</span>
            人与您互评，若分组不正确，请联系BP在民主评议开始前完成修正
          </p>
          <!-- 组织  -->
          <p v-else>
            共参与
            <span class="org">{{ $route.query.orgNum }}</span>
            个组织互评，若分组不正确，请联系BP在民主评议开始前完成修正
          </p>
        </div>
      </div>
      <!-- approveUserName 邀请人姓名 -->
      <!-- 展示人员 -->
      <div class="inviter-form" v-if="this.$route.query.wayType == 0">
        <!-- 组织  -->
        <div v-if="$route.query.type == 0">
          <div class="org-intive-people" v-for="(items, index) in orginvitePeople" :key="index">
            <div class="intive-people-title">
              <h3>{{ items.partakeOrgName }}({{ items.reportUsers.length }}人)</h3>
              <span>{{ items.beginTime }}生效</span>
            </div>
            <div class="inviter-people-show">
              <el-tag v-for="item in items.reportUsers" :key="item.userName" type="info" effect="plain">
                {{ item.userName }}
              </el-tag>
            </div>
          </div>
        </div>
        <!-- 员工  -->
        <div v-else>
          <div class="org-intive-people">
            <div class="intive-people-title">
              <h3>你的评价人({{ user.total }}人)</h3>
              <span>应用于{{ user.beginTime }}后开始的民主评议</span>
            </div>
            <div class="inviter-people-show">
              <el-tag v-for="item in user.reportUsers" :key="item.userName" type="info" effect="plain">
                {{ item.userName }}
              </el-tag>
            </div>
          </div>
        </div>
      </div>
      <!-- 员工邀请 -->
      <!-- <component :is="'InviterTitleComp'" :schema="currentState" /> -->
      <div class="inviter-form" v-else>
        <!-- 通过展示 -->
        <h3 v-if="currentState == 'auditPass'" class="passStyle">
          <p>以下评价人审核已通过</p>
          <span class="passTip">若无组织人员变动，后续每次评价均使用以下人员</span>
        </h3>
        <component
          v-if="currentState"
          ref="inviterRef"
          :is="assemblyData[currentState].comp"
          :data="assemblyData[currentState].data"
          :payload="assemblyData[currentState].payload"
          :disabled="assemblyData[currentState].attrs.disabled"
        ></component>
      </div>
    </section>
    <div class="inviter-footer">
      <el-button v-show="currentState == 'amendInvite' && this.$route.query.wayType == 1" plain @click="cancelBtn">取消</el-button>
      <component
        v-if="currentState && this.$route.query.wayType == 1"
        v-bind="assemblyData[currentState].buttonComp.attrs"
        :is="assemblyData[currentState].buttonComp.comp"
        :btn-text="assemblyData[currentState].buttonComp.text"
        @click="assemblyData[currentState].buttonComp.handler"
      />
      <!-- {{ $route.query.wayType }} -->
      <el-button v-if="$route.query.wayType === 0" plain @click="$router.push({ name: 'democracy-mine' })">返回</el-button>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { MessageBox, Message } from 'element-ui'
import TojoyTitleContent from '@/components/common/initiate-team/title-content'
import SmallUserCard from '@/components/democracy/small-user-card.vue'
import InviterPeopleShow from '@/components/democracy/inviter-people-show.vue'
import InviterPeopleOpera from '@/components/democracy/inviter-people-opera.vue'
import InviterButtons from '@/components/democracy/inviter-buttons.jsx'
import { InviterTitleComp, InviterTitleState } from '@/components/democracy/state.js'
import { inviteDetail, inviteSave, inviteApprove, orgGroupList, perReceList } from '@/api/api_democracy'
import { mapState } from 'vuex'
const mockShowData = () => {
  let data = []

  for (let index = 0; index < 100; index++) {
    data.push({
      id: 2016 + index,
      name: `韩梅梅${index}`
    })
  }

  return data
}

const Button_Back = {
  comp: InviterButtons,
  text: '取消',
  attrs: { plain: true }
}

const Button_Audit = {
  comp: InviterButtons,
  text: '提交审批',
  attrs: { type: 'primary' }
}

const Button_Repeal = {
  comp: InviterButtons,
  text: '撤回修改',
  attrs: { plain: true }
}

export default {
  name: 'democracy-inviter-view',
  components: {
    TojoyTitleContent,
    SmallUserCard,
    InviterTitleComp,
    InviterTitleState
  },
  data() {
    return {
      inviterData: mockShowData(),
      inviterPerson: {}, //邀请人信息
      inviterFormData: {
        inviterPeople: [], //被邀请人集合
        approvePeople: [] //邀请人
      },
      inviterNums: {
        maxNum: 0,
        minNum: 0
      },
      minNum: '',
      invite: 'invite',
      currentState: 'allInvite', // awaitInvite | auditing | auditPass | yetReject | auditAutoReject |auditReject | amendInvite
      orginvitePeople: [], //被邀请人的数据
      stateAttrs: {
        0: 'awaitInvite', //待邀请
        1: 'auditing', //审批中
        10: 'auditPass', //审批通过
        12: 'yetReject', //已驳回
        13: 'auditAutoReject', //系统自动驳回
        14: 'auditReject', //管理员驳回
        20: 'amendInvite' //撤回修改
      },
      user: {
        // orgReportUsers: []
      }
    }
  },
  computed: {
    ...mapState({
      avatar: state => state.userInfo.avatar,
      userName: state => state.userInfo.userName,
      orgChain: state => (state.userInfo.jobList && state.userInfo.jobList.length > 0 ? state.userInfo.jobList[0].orgChain : ''),
      jobName: state => (state.userInfo.jobList && state.userInfo.jobList.length > 0 ? state.userInfo.jobList[0].jobName : ''),
      backlogCount: state => state.msgNumber.backlogCount,
      companyName: state => state.userInfo.companyName,
      avatarName: state => (state.userInfo.userName ? state.userInfo.userName.slice(-2) : '')
    }),
    assemblyData() {
      return {
        // 待邀请
        awaitInvite: {
          comp: InviterPeopleOpera,
          data: this.inviterFormData,
          payload: {
            ...this.inviterPerson
          },
          buttonComp: {
            ...Button_Audit,
            handler: this.handleSubmit // TODO
          },
          attrs: {}
        },
        // 已驳回
        yetReject: {
          comp: InviterPeopleOpera,
          data: this.inviterFormData,
          payload: {
            ...this.inviterPerson
          },
          buttonComp: {
            ...Button_Audit,
            handler: this.handleSubmit // TODO
          },
          attrs: {}
        },
        // 系统自动驳回
        auditAutoReject: {
          comp: InviterPeopleOpera,
          data: this.inviterFormData,
          payload: {
            ...this.inviterPerson
          },
          buttonComp: {
            ...Button_Audit,
            handler: this.handleSubmit // TODO
          },
          attrs: { disabled: false }
        },
        // 管理员驳回
        auditReject: {
          comp: InviterPeopleOpera,
          data: this.inviterFormData,
          payload: {
            ...this.inviterPerson
          },
          buttonComp: {
            ...Button_Audit,
            handler: this.handleSubmit // TODO
          },
          attrs: { disabled: false }
        },
        // 审批中
        auditing: {
          comp: InviterPeopleOpera,
          data: this.inviterFormData,
          payload: {
            ...this.inviterPerson
          },
          buttonComp: {
            ...Button_Repeal,
            handler: this.recallChange // TODO
          },
          attrs: { disabled: true }
        },
        // 审批通过
        auditPass: {
          comp: InviterPeopleOpera,
          data: this.inviterFormData,
          payload: {
            ...this.inviterPerson
          },
          buttonComp: {
            ...Button_Repeal,
            handler: this.recallChange // TODO
          },
          attrs: { disabled: true }
        },
        // 修改邀请
        amendInvite: {
          comp: InviterPeopleOpera,
          data: this.inviterFormData,
          payload: {
            ...this.inviterPerson
          },
          buttonComp: {
            ...Button_Audit,
            handler: this.handleSubmit // TODO
          },
          attrs: { disabled: false }
        },
        //全部数据
        allInvite: {
          comp: InviterPeopleOpera,
          data: this.inviterFormData,
          payload: {
            ...this.inviterPerson
          },
          buttonComp: {
            ...Button_Back,
            handler: () => {} // TODO
          },
          attrs: { disabled: false }
        }
      }
    }
  },
  created() {
    this.getDetail()
    this.getOrgList()
    this.loadReceivedData()
    // console.log('wayType', this.$route.query.wayType)
  },
  methods: {
    //提交审批
    handleSubmit() {
      // console.log(this.currentState, '当前状态')
      const _form = this.$refs['inviterRef'].$refs['inviterForm']
      _form.validate(valid => {
        if (valid) {
          const _inviteList = this.inviterFormData?.inviterPeople.map(i => i.userId)
          // console.log(this.inviterFormData?.inviterPeople, '被邀请人集合', _inviteList)
          let params = {
            approveUserId: this.inviterFormData.approvePeople.userId, // 审核用户id
            // auditUserName: this.inviterFormData.approvePeople.userName, // 审核用户姓名
            invitedUserIdList: _inviteList //邀请列表
          }
          // console.log(params, '传回参数')
          inviteSave(params).then(res => {
            if (res.code === '000000') {
              this.$msgSuccess('提交成功请跟进审批人及时完成审批后生效')
              this.getDetail()
              this.currentState = this.stateAttrs[1]
            }
          })
        } else {
          return false
        }
      })
    },
    //撤回修改
    recallChange() {
      MessageBox.confirm('', '确定要撤回吗？', {
        confirmButtonText: '确定撤回',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.currentState = this.stateAttrs[20]
        })
        .catch(() => {
          Message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },

    cancelBtn() {
      // this.handleSubmit()
      // this.currentState = 'amendInvite'
      this.getDetail()
    },
    getDetail() {
      inviteDetail({}).then(res => {
        if (res.code == '000000') {
          this.inviterPerson = res.data ?? {}
          //状态赋值
          if (this.inviterPerson.approveState) {
            this.currentState = this.stateAttrs[res.data.approveState]
          } else {
            this.currentState = this.stateAttrs[0]
          }
          //被邀请人处理
          const _inviterPersons = this.inviterPerson?.invitedUserVoList?.map(o => {
            return {
              userId: o.userId,
              userName: o.userName
            }
          })
          //返回被邀请人
          if (_inviterPersons?.length) {
            this.inviterFormData.inviterPeople = [..._inviterPersons]
          }
          //邀请人id和name处理
          const _approvePeople = this.inviterPerson?.approveUserId
            ? {
                userId: this.inviterPerson?.approveUserId,
                userName: this.inviterPerson?.approveUserName
              }
            : {}
          if (Object.keys(_approvePeople).length) {
            this.inviterFormData.approvePeople = { ..._approvePeople }
          }
        }
      })
    },
    //获取组织全部数据
    getOrgList() {
      this.loading = true
      orgGroupList({}).then(res => {
        if (res.code === '000000') {
          this.orginvitePeople = res.data
          this.num = this.orginvitePeople.length
          this.loading = false
        }
      })
    },

    // 个人全部数据
    loadReceivedData(options = {}) {
      this.loading = true
      let params = {
        page: 1,
        size: 1000
      }
      perReceList({ ...params }, options).then((res, data) => {
        if (res.code === '000000') {
          this.user = res.data ?? {}
          this.loading = false
        }
      })
    }
  }
}
</script>

<style lang="scss">
.democracy-inviter-view {
  height: 100%;
  overflow: auto;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  &__section {
    border-radius: 5px;
    background: $white;
    margin: 20px 30px;
    padding: 20px 15px;
    overflow: auto;
    display: flex;
    flex-direction: column;
    flex: 1;
    &_state {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: solid 1px #eeeeee;
      & > .small-user-card {
        padding-bottom: 20px;
        .username {
          font-size: 18px;
          font-weight: bold;
          color: $lightblack;
          line-height: 18px;
        }
      }
      & > .allStype {
        // margin-top: 50px;
        color: #666666;
        font-size: 12px;
        padding: 7px 10px;
        background-color: #fef2e8;
        p {
          .org {
            color: #fe831a;
          }
        }
      }
      div {
        & > .inviter-title {
          font-size: 16px;
          color: $lightblack;
          margin: 30px 5px;

          &--orange {
            color: #fe831a;
          }
          &--blue {
            color: #3d7eff;
          }
          &--green {
            color: $green;
          }
          &--red {
            color: $red;
          }
          span {
            font-size: 14px;
            font-weight: 500;
            line-height: 14px;
          }
          // 邀请人的样式
          .invite-style {
            &-s {
              display: flex;
              justify-content: flex-end;
              margin-bottom: 15px;
              font-weight: bold;
            }
            .desc {
              max-width: 600px;
              padding: 7px 10px;
              font-size: 12px;
              text-align: end;
              text-justify: newspaper;
              word-break: break-all;
              &--green {
                background-color: #e6f6ee;
              }
              &--orange {
                background-color: #fef2e8;
              }
              &--blue {
                background-color: #ebf2ff;
              }
              &--red {
                background-color: #ffedf0;
              }
            }
          }
        }
      }
    }

    & > .inviter-form {
      flex: 1;
      overflow: auto;
      .passStyle {
        display: flex;
        align-items: center;
        margin: 30px 0px;
        color: #06ae56;
        .passTip {
          margin-left: 15px;
          font-size: 12px;
          font-weight: 400;
          color: #999999;
        }
      }
    }
    .org-intive-people {
      margin-top: 30px;
      .intive-people-title {
        display: flex;
        align-items: center;
        margin-bottom: 30px;
        span {
          margin-left: 15px;
          color: #999999;
          font-size: 12px;
        }
      }
    }
  }
  .inviter-footer {
    text-align: right;
    background: $white;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
    padding: 15px 30px;
    .el-button {
      width: 166px;
    }
  }
}
</style>
